export default {
  props: {
    destination: {
      type: Object,
      require: true
    },
    newTab: {
      type: Boolean,
      require: true
    }
  },
  computed: {
    target() {
      let targetString = '';
      if (this.newTab) {
        targetString = '_blank';
      }
      return targetString;
    }
  }
};