import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('router-link', {
    attrs: {
      "to": _vm.destination,
      "target": _vm.target
    }
  }, [_c('LBAButton', {
    attrs: {
      "buttonStyle": 'icon'
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function () {
        return [_c(VIcon, {
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" mdi-eye ")])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };